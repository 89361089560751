import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n.js";

import { BootstrapVue } from 'bootstrap-vue'
import './styles/_import.scss'
Vue.use(BootstrapVue)

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
