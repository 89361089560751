<template>
  <video
    :id="vId"
    playsinline
    muted
    width="100%"
    height="100%"
    autoplay
    :class="{ hidden: videoHidden }"
  >
    <source :src="videoPath" type="video/mp4" />
  </video>
</template>

<script>
export default {
  props: ["vId", "videoPath"],

  data() {
    return {
      isIos: false,
      videoHidden: true,
    };
  },

  created() {
    if (this.getMobileOS() === "iOS") {
      this.isIos = true;
    }
  },

  mounted() {
    var vid = document.getElementById(this.vId);
    setTimeout(() => {
      this.videoHidden = false;
      vid.src = this.videoPath;

      setTimeout(() => {
        this.$emit("startFadeIn");
      }, 2000);
    }, 1000);
  },

  methods: {
    getMobileOS() {
      const ua = navigator.userAgent;
      if (/android/i.test(ua)) {
        return "Android";
      } else if (
        /iPad|iPhone|iPod/.test(ua) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
      ) {
        return "iOS";
      }
      return "Other";
    },
  },
};
</script>
