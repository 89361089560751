<template>
  <div class="home">
    <div class="top-part">
      <div>
        <b-container>
          <!-- <div class="video-container" id="vc">
            <video-component
              vId="animVideo"
              :videoPath="$t('videoPath')"
              @startFadeIn="showIntroTxt = true"
            ></video-component>
          </div> -->

          <div class="gif-container">
            <img :src="$t('gifPath')" />
          </div>

          <div
            id="post-anim-fade-in"
            class="fade-me-in"
            :class="{ hidden: !showIntroTxt }"
          >
            <h2>{{ $t("title") }}</h2>
            <img class="arrow-anim mt-4" src="/img/arrow-down.svg" />
          </div>
        </b-container>
      </div>
    </div>

    <div class="bottom-part">
      <b-container>
        <p>
          {{ $t("text") }}
        </p>

        <b-row v-show="videosLoaded > 1">
          <b-col cols="6">
            <img
              class="play-icon"
              src="/img/play-btn.svg"
              @click="play('umirjena')"
            />
            <p class="mt-4">{{ $t("btn1") }}</p>
          </b-col>
          <b-col cols="6">
            <img
              class="play-icon"
              src="/img/play-btn.svg"
              @click="play('dramaticna')"
            />
            <p class="mt-4">{{ $t("btn2") }}</p>
          </b-col>
        </b-row>

        <div v-show="videosLoaded < 2" class="py-5">
          <b-spinner></b-spinner>
        </div>

        <div class="audio-container mt-5">
          <div>
            <audio
              v-show="playing === 'umirjena'"
              id="umirjena"
              :src="$t('audioUmirjenaPath')"
              controls
            ></audio>

            <audio
              v-show="playing === 'dramaticna'"
              id="dramaticna"
              :src="$t('audioDramaticnaPath')"
              controls
            ></audio>
          </div>
        </div>
        <div class="wf-video-container" id="wf-v">
          <video
            playsinline
            muted
            width="100%"
            height="100%"
            id="umirjena-v"
            v-show="playing === 'umirjena'"
            :src="$t('wfUmirjenaPath')"
          ></video>
          <video
            playsinline
            muted
            width="100%"
            height="100%"
            id="dramaticna-v"
            v-show="playing === 'dramaticna'"
            :src="$t('wfDramaticnaPath')"
          ></video>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
// import videoComponent from "@/components/videoComponent.vue";

export default {
  components: {
    // videoComponent,
  },

  data() {
    return {
      showIntroTxt: false,
      playing: null,
      currentAudio: null,
      currentVideo: null,
      isIos: false,
      videoHidden: true,
      videosLoaded: 0,
    };
  },

  created() {},

  mounted() {
    this.detectMediaLoaded();
    this.setVidContainerHeight();
    window.addEventListener("resize", () => {
      this.setVidContainerHeight();
    });

    setTimeout(() => {
      this.showIntroTxt = true;
    }, 2500);
  },

  methods: {
    play(audioId) {
      window.scrollTo(0, document.body.scrollHeight);
      if (this.playing === audioId) return; // return if clicked on same play button

      this.playing = audioId;

      if (this.currentAudio) {
        // remove listeners
        this.currentAudio.removeEventListener("pause", () => {});
        this.currentVideo.removeEventListener("play", () => {});
        this.currentVideo.removeEventListener("seeked", () => {});

        // clear current audio and video
        this.currentAudio.pause();
        this.currentAudio.currentTime = 0;
        this.currentAudio = null;
        this.currentVideo.pause();
        this.currentVideo.currentTime = 0;
        this.currentVideo = null;
      }
      // set new current audio and video
      this.currentAudio = document.getElementById(audioId);
      this.currentVideo = document.getElementById(audioId + "-v");
      this.currentAudio.play();
      this.currentVideo.play();

      // add listeners - used to synch audio and video
      this.currentAudio.addEventListener("pause", () => {
        this.currentVideo.pause();
      });
      this.currentAudio.addEventListener("seeked", () => {
        this.currentVideo.currentTime = this.currentAudio.currentTime;
      });
      this.currentAudio.addEventListener("play", () => {
        this.currentVideo.play();
      });
    },

    detectMediaLoaded() {
      var uv = document.getElementById("umirjena-v");
      var dv = document.getElementById("dramaticna-v");

      uv.addEventListener("loadeddata", () => {
        console.log("uv loaded");
        this.videosLoaded++;
      });
      dv.addEventListener("loadeddata", () => {
        console.log("dv loaded");
        this.videosLoaded++;
      });

      setTimeout(() => {
        this.videosLoaded = 2;
      }, 3000);
    },

    setVidContainerHeight() {
      var vc = document.getElementById("vc");
      if (vc) {
        vc.style.minHeight = vc.offsetWidth * 0.5 + "px";
      }

      vc = document.getElementById("wf-v");
      vc.style.minHeight = vc.offsetWidth * 0.45 + "px";
    },
  },
};
</script>
