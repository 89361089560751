<template>
  <div id="app">
    <!-- <video-component vId="vidBg" videoPath="video/bg2.mp4"></video-component> -->
    <router-view />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import videoComponent from "@/components/videoComponent.vue";

export default {
  components: { videoComponent },

  mounted() {
    window.scrollTo(0, 0);
    this.setVH();
  },

  methods: {
    setVH() {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight}px`
      );

      window.addEventListener("resize", () => {
        document.documentElement.style.setProperty(
          "--vh",
          `${window.innerHeight}px`
        );
      });
    },
  },
};
</script>


<style>
</style>
